<template>
  <v-checkbox
    v-if="shouldDisplay"
    v-model="isNegativeOptionChecked"
    :label="$t('subscriptionConfirmation.checkboxMessage')"
    color="primary"
    data-qa="negative-option-checkbox"
    required
  ></v-checkbox>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NegativeOptionCheckbox',
  data() {
    return {
      confirmDisabled: true,
      isNegativeOptionChecked: false,
      shouldDisplay: true,
    };
  },
  mounted() {
    this.shouldShowForCountry();
  },
  computed: {
    ...mapGetters('auth', ['companyCountryCode']),
    ...mapGetters('configuration', ['countriesToShowNegativeOptions']),
  },
  methods: {
    shouldShowForCountry() {
      if (
        this.countriesToShowNegativeOptions.includes(this.companyCountryCode)
      ) {
        return true;
      } else {
        this.shouldDisplay = false;
        this.$emit('confirmDisable', {
          confirmDisabled: false,
        });
        return false;
      }
    },
  },
  watch: {
    isNegativeOptionChecked() {
      this.confirmDisabled = !this.isNegativeOptionChecked;
    },
    confirmDisabled() {
      this.$emit('confirmDisable', {
        confirmDisabled: this.confirmDisabled,
      });
    },
  },
};
</script>
<style scoped>
.v-input--checkbox::v-deep {
  .v-label {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
